import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .notValidPageMessage {
    font-size: 20px;
  }
  .autoRedirectInfo {
    font-size: 16px;
  }
`;

function NotFound() {
  const router = useRouter();

  useEffect(() => {
    router.replace('/');
  }, []);

  return (
    <NotFoundContainer>
      <p className="notValidPageMessage">
        Unfortunately the page you are looking for doesn't exists
      </p>
      <p className="autoRedirectInfo">Redirecting you to homepage...</p>
    </NotFoundContainer>
  );
}

export default NotFound;
